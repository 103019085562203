import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';

export const initialState = {
  birth_date: null,
  company_name: null,
  created_at: null,
  creator: null,
  custom_fields: [],
  email: null,
  first_name: null,
  id: null,
  image: null,
  instance: null,
  job_title: null,
  last_name: null,
  middle_name: null,
  name: null,
  phone_number: null,
  prefix: null,
  scopes: [],
  isAdmin: null,
  status: null,
  street_address: null,
  type: null,
  updated_at: null,
  mFA: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const getUser = (token, instance) => async dispatch => {
  const res = await fetch(`${process.env.BASE_PATH}/api/users/${instance}`, {
    headers: {
      Authorization: token,
    },
  });
  if (res.ok) {
    const { scopes, isAdmin, user, id } = await res.json();

    dispatch(set({ scopes, isAdmin, instance: id, ...user }));
  } else if (res.status == 401) {
    dispatch(set({ ...initialState, instance: null }));
  }
};
